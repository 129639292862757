<template>
	<div class="xwlist">
		<div class="xwbx">
			<div style="width:40%">
				<h2 style="width:40%">新闻动态</h2>
				<h3 style="width:40%">News</h3>
			</div>
			<div class="newsimg" @click="subxqy(newsid)">
				<img  style="width:100%;height:100%;z-index:-1" :src="newsimgg" alt="">
				<div style="width:89%;height:80px;z-index:2;text-align: left; margin-top: -19%;margin-left:11%">
					<h3>{{newstext}}</h3>
					<p>{{newsinfo}}</p>
					<p>{{newstime}}</p>
				</div>
			</div>
			<div class="newslist">
				<el-tabs v-model="activeName"  @tab-click="handleClick">
					<el-tab-pane label="公司动态" name="first" >
						<div class="newcontent" v-for="(item,index) in newListshow.slice(1)" :key="index">
							<img class="textimg" :src="item.filePath" alt="">
							<div class="newtext">
								<h3>{{item.title}}</h3>
								<p>{{item.intro}}</p>
								<p>{{item.createTime}}</p>
								<el-button class="more" type="primary" @click="subdetails(item.id)" >了解更多 ></el-button>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="行业资讯" name="second">
						<div class="newcontent" v-for="(item,index) in newListtext.slice(0)" :key="index">
							<img class="textimg" :src="item.filePath" alt="">
							<div class="newtext">
								<h3>{{item.title}}</h3>
								<p>{{item.intro}}</p>
								<p>{{item.createTime}}</p>
								<el-button class="more" type="primary" @click="subdetails(item.id)" >了解更多 ></el-button>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="常见问题" name="third">
						<div class="newcontent" v-for="(item,index) in newlistcjwt.slice(0)" :key="index">
							<img class="textimg" :src="item.filePath" alt="">
							<div class="newtext">
								<h3>{{item.title}}</h3>
								<p>{{item.intro}}</p>
								<p>{{item.createTime}}</p>
								<el-button class="more" type="primary" @click="subdetails(item.id)" >了解更多 ></el-button>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "newsComp",
	data(){
		return {
			// 图1:
			newsimgg:'',
			newstext:'',
			newsinfo:'',
			newstime:'',
			newsid:'',
			activeName:'first',
			// 公司动态
			newListshow:[],
			// 行业咨询
			newListtext:[],
			// 常见问题
			newlistcjwt:[],
		}
	},
	mounted() {
		this.newlist();
		this.newlistzx();
		this.newlistwt();
	},
	methods:{
		subxqy(res) {
			//  this.$router.push('./details.vue?type="bigImage"')
			//    console.log('获取到的id',res)
			localStorage.setItem('id1', res);
			this.$router.push({
				path:'./details.vue',
				query:{
					type:"bigImage"
				}
			})
		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		// 跳转新闻详情页面
		subdetails(info) {
			this.$router.replace('/index/detailsComp');
			localStorage.setItem('id', info);
		},
		// 获取新闻列表公司动态
		async newlist() {
			var that = this;
			const {data:res}=await this.$http.get('/gw/news/getWzNewsList?type=1&limit=100');
			that.newListshow= res.data;
			that.newsimgg=res.data[0].filePath;
			that.newsinfo=res.data[0].intro;
			that.newstime=res.data[0].createTime;
			that.newstext=res.data[0].title;
			that.newsid=res.data[0].id;
		},
		// 获取新闻列表行业咨询
		async newlistzx() {
			var that = this;
			const {data:res}=await this.$http.get('/gw/news/getWzNewsList?type=7&limit=100');
			that.newListtext= res.data;
		},
		//   // 获取新闻列表常见问题
		async newlistwt() {
			var that = this;
			const {data:res}=await this.$http.get('/gw/news/getWzNewsList?type=8&limit=100');
			that.newlistcjwt= res.data;
		},
	}
}
</script>

<style scoped>
.menu>.el-button {
	border-radius: 0;
	margin: 0;
	border: 0;
	border-left: 1px solid #bdbdbd;
	background-color: #0f91ee;
	color: #fff;
}
.introduction1>div {
	display: inline-block;
	width: 300px;
	/* margin-top: 50PX;
	margin-left: 150px; */
}
.introduction1>div>img{
	padding-top: 50px;
	padding-left: 95px;
}
.introduction1>div>h3{
	color: #717171;
	text-align: center;
	margin-top: 8px;
	
}
.introduction1>div>p{
	color: #717171;
	text-align: center;
	width: 250px;
	margin-left: 20px;
}
.xm>h2{
	margin-left: 19%;
}
.el-dropdown + .el-dropdown {
	margin-left: 15px;
	width: 500px !important;
}
.cooperation>div {
	display: inline-block;
	margin-right: 1%;
	margin-top: 1%;
	margin-bottom: 1%;
	width: 15%;
	height: 50px;
	border: 1px solid #717171;
	text-align: center;
	overflow: hidden;
	font-size: 13px;
}
.cooperation>div>span {
	width: 188px;
	height: 50px;
	text-align: center;
	margin-top: 50px;
	line-height: 44px;
}
.footerkuai>div {
	display: inline-block;
	/* border: 1px solid #fff; */
	color: #4b4f54;
}
.sj1>p {
	display: inline-block;
}
.dhbj>p {
	position: absolute;
	top: 5px;
	color: #494d52;
}
.lyti>h3{
	display: inline-block;
	font-size: 18px;
	margin-bottom: 0;
}
.specific>img {
	position: absolute;
	top: 15%;
	display: inline-block;
}
.specific>p {
	display: inline-block;
	margin-left: 15%;
	color: #c2c2c2;
}
.specific1>img {
	position: absolute;
	top: 34%;
	display: inline-block;
}
.specific1>p {
	display: inline-block;
	margin-left: 15%;
	color: #c2c2c2;
}
.specific2>img {
	position: absolute;
	top: 30%;
	display: inline-block;
}
.specific2>p {
	display: inline-block;
	margin-left: 15%;
	color: #c2c2c2;
}
.specific3>img {
	position: absolute;
	top: 30%;
	display: inline-block;
}
.specific3>p {
	display: inline-block;
	margin-left: 15%;
	color: #c2c2c2;
	margin-top: 0;
	width: 80%;
}
.xwlist {
	width: 100%;
	height: 650px;
}
.xwbx {
	width: 61%;
	height: 650px;
	margin-left: 19%;
}
.newsimg {
	margin-top: 3%;
	width: 45%;
	height: 69%;
}
.newslist {
	width: 50%;
	height: 90%;
	margin-top: -45%;
	margin-left: 50%;
	overflow-y: auto;
}
.newtext {
	display: inline-block;
	width: 50%;
	height: 100px;
	margin-left: 12%;
	
}
.textimg {
	
	display: inline-block;
	width: 30%;
	height: 118px;
	margin-top: 6% !important;
}
.newcontent {
	width: 100%;
	border-bottom: 1px solid #717171;
	box-sizing: border-box;
	padding: 10px 0;
	overflow-y: auto;
}
.more {
	margin-left: 80%;
	width: 35%;
	height: 20%;
	line-height: 20%;
}

</style>
